import Api from "@/services/Api";

export default {
  getUserInfo(id) {
    return Api().get("getUserInfo", id);
  },
  updateUserInfo(info) {
    return Api().post("updateUserInfo", info);
  },
  changePassword(info) {
    return Api().post("changePassword", info);
  },
  createUser(credentials) {
    return Api().post("createUser", credentials);
  },
  masterCreateUser(credentials) {
    return Api().post("masterCreateUser", credentials);
  },
  getAllUsers() {
    return Api().get("getAllUsers");
  },
  delete(id) {
    return Api().delete(`/user/${id}`);
    // return Api().delete('/entry/' + id);
  },
  masterDelete(id) {
    return Api().delete(`/masterUser/${id}`);
  },
};
