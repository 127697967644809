<template>
  <v-layout column>
    <div class="align-center text-center">
      <v-card elevation="6" max-width="800" style="margin: auto">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.languageSettings") }}</v-toolbar-title>
        </v-toolbar>

        <v-col>
          <v-data-iterator
            :items="userSettings"
            :items-per-page="1"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.prefLang"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="12"
                >
                  <v-card>
                    <v-divider></v-divider>

                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content
                          >{{
                            $t("global.preferredlanguage")
                          }}:</v-list-item-content
                        >
                        <v-list-item-content class="align-end">
                          {{ item.prefLang }}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-col>

        <div class="d-flex mt-4">
          <v-flex class="md3 ml-4">
            <v-container fluid>
              <v-select
                class=""
                v-model="language"
                :items="languages"
                :hint="$t('global.maximum1Value')"
                :label="$t('global.language')"
                multiple
                persistent-hint
                small-chips
                outlined
                backgroundColor="white"
              >
              </v-select>
            </v-container>
          </v-flex>

          <v-flex class="md4 mt-3 ml-6">
            <v-text-field
              v-model="password"
              :label="$t('global.currentPassword')"
              autocomplete="off"
              :append-icon="value ? 'visibility' : 'visibility_off'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              filled
              outlined
              backgroundColor="white"
            ></v-text-field>
          </v-flex>

          <v-flex class="md3 mt-5">
            <v-btn elevation="2" @click="updateAppLanguage">
              {{ $t("button.change") }}
            </v-btn>
          </v-flex>
          <br />
        </div>
        <br />
        <div class="red--text" v-html="error" />
        <div class="green--text" v-html="success" />
      </v-card>
    </div>



    <div class="align-center text-center mt-1">
      <v-card elevation="6" max-width="800" style="margin: auto">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.personalInfo") }}</v-toolbar-title>
        </v-toolbar>
        <!--class that defines paddings(left, right, top && bottom-->
        <div class="pl-4 pr-4 pt-2 pb-4">
          <form name="register-form" autocomplete="off">
            <v-layout row class="mx-0 mt-3">
              <v-flex>
                <v-text-field
                  v-model="firstName"
                  :label="$t('global.firstName')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex>
                <v-text-field
                  v-model="lastName"
                  :label="$t('global.lastName')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex class="md7">
                <v-text-field
                  class=""
                  v-model="address"
                  :label="$t('global.address')"
                  filled
                ></v-text-field>
              </v-flex>
              <v-flex class="md4 ml-16">
                <v-text-field
                  class=""
                  v-model="zipCode"
                  :label="$t('global.zipCode')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex>
                <v-text-field
                  v-model="nationality"
                  :label="$t('global.nationality')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex>
                <v-text-field
                  v-model="nif"
                  :label="$t('global.nif')"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row class="mx-0">
              <v-flex class="md7">
                <v-text-field
                  v-model="billingNumber"
                  label="Billing Card Number"
                  filled
                ></v-text-field>
              </v-flex>
              <v-flex class="md2 ml-8">
                <v-text-field v-model="csv" label="CSV" filled></v-text-field>
              </v-flex>
              <v-flex class="md2 ml-8">
                <v-text-field
                  v-model="expireDate"
                  label="Expire Date"
                  filled
                ></v-text-field>
              </v-flex>
            </v-layout>
          </form>
          <br />

          <v-row>
            <v-col>
              <v-flex class="md12 ml-0">
                <v-text-field
                  v-model="password"
                  :label="$t('global.insertPassword')"
                  autocomplete="off"
                  :append-icon="value4 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (value4 = !value4)"
                  :type="value4 ? 'password' : 'text'"
                  filled
                ></v-text-field>
              </v-flex>
            </v-col>
            <v-col>
              <v-btn width="8vw" class="mt-2 ml-0" elevation="2" @click="updateUserInfo">
                {{ $t("button.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <br />
        <div class="red--text" v-html="error2" />
        <div class="green--text" v-html="success2" />
      </v-card>
    </div>


    <div class="align-center text-center mt-1">
      <v-card elevation="6" max-width="800" style="margin: auto">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.changePassword") }}</v-toolbar-title>
        </v-toolbar>

        <div class="d-flex mt-4">
          <v-flex class="md4 ml-6">
            <v-text-field
              v-model="password1"
              :label="$t('global.currentPassword')"
              autocomplete="off"
              :append-icon="value1 ? 'visibility' : 'visibility_off'"
              @click:append="() => (value1 = !value1)"
              :type="value1 ? 'password' : 'text'"
              filled
              outlined
              backgroundColor="white"
            ></v-text-field>
          </v-flex>

          <v-flex class="md4 ml-6">
            <v-text-field
              v-model="newPassword"
              :label="$t('global.newPassword')"
              autocomplete="off"
              :append-icon="value2 ? 'visibility' : 'visibility_off'"
              @click:append="() => (value2 = !value2)"
              :type="value2 ? 'password' : 'text'"
              filled
              outlined
              backgroundColor="white"
            ></v-text-field>
          </v-flex>

          <v-flex class="md4 ml-6">
            <v-text-field
              v-model="newPassword2"
              :label="$t('global.repeatNewPass')"
              autocomplete="off"
              :append-icon="value3 ? 'visibility' : 'visibility_off'"
              @click:append="() => (value3 = !value3)"
              :type="value3 ? 'password' : 'text'"
              filled
              outlined
              backgroundColor="white"
            ></v-text-field>
          </v-flex>

          <v-flex class="md3 mt-3">
            <v-btn elevation="2" @click="changePassword">
              {{ $t("button.change") }}
            </v-btn>
          </v-flex>
          <br />
        </div>
        <br />
        <div class="red--text" v-html="error1" />
        <div class="green--text" v-html="success1" />
      </v-card>
    </div>

  </v-layout>
</template>

<script>
import UserService from "@/services/UserService";
// import HelperMethods from "@/utilities/HelperMethods";

export default {
  data() {
    return {
      userSettings: [{}],
      languages: ["English", "Português"],
      language: "",
      value: String,
      value1: String,
      value2: String,
      value3: String,
      value4: String,
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      nationality: "",
      nif: "",
      billingNumber: "",
      csv: "",
      expireDate: "",
      password: null,
      password1: null,
      newPassword: null,
      newPassword2: null,
      success: null,
      success1: null,
      success2: null,
      error: null,
      error1: null,
      error2: null,
    };
  },
  methods: {
    async updateAppLanguage() {
      let language = "";
      if (this.language == "English") {
        language = "en";
      } else if (this.language == "Português") {
        language = "pt";
      } else language = null;

      try {
        const response = await UserService.updateUserInfo({
          user: this.$store.getters.userId,
          prefLang: String(language),
          password: this.password,
        });
        console.log("response: ", response);

        if (language == "en") {
          this.success =
            "Language successfully updated. Restart the app to apply the changes.";
        } else
          this.success =
            "Idioma actualizado com sucesso. Reinicie a App para aplicar as alterações.";
        this.error = null;
      } catch (error) {
        if (this.i18n.locale === "pt") {
          this.error = error.response.data.error + "<br/> Verifique a password dada e tente novamente.";
        } else this.error = error.response.data.error + "<br/> Check the password given and try again.";
        
      }
    },

    async updateUserInfo() {
      try {
        const response = await UserService.updateUserInfo({
          user: this.$store.getters.userId,
          firstName: this.firstName,
          lastName: this.lastName,
          address: this.address,
          zipCode: this.zipCode,
          nationality: this.nationality,
          nif: this.nif,
          password: this.password,
        });
        this.error2 = null;
        this.success2 = "User info successfully updated."
        console.log("response: ", response);
      } catch (error) {
        this.error2 = error.response.data.error;
      }
    },

    async changePassword() {
      if ((this.password || this.newPassword || this.newPassword2) == null) {
        return (this.error1 = "Error. All fields must be filled.");
      } else if (this.newPassword != this.newPassword2) {
        return (this.error1 = "New passwords don't match.");
      }
      try {

      const response = await UserService.changePassword({
        id: this.$store.getters.userId,
        password: this.password1,
        newPassword: this.newPassword,
      });
      console.log("response: ", response);

      this.error1 = response.data.error;
      this.success1 = response.data.success;

    } catch (err) {
      this.error1 = err.response.data.error;
      console.log("err: ", err);
    }
    },
  },

  async mounted() {
    let prefLang = this.$i18n.locale;
    if (prefLang == "en") {
      this.userSettings[0].prefLang = "English";
    } else if (prefLang == "pt") {
      this.userSettings[0].prefLang = "Português";
    } else this.userSettings[0].prefLang = null;
  },
  watch: {
    language(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.language.pop());
      }
    },
  },
};
</script>
